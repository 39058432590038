import React, { Component } from 'react';
import logo from './logo.svg';
import './assets/css/global.css';
import './assets/css/App.css';
import NavBar from './navBar';
import { BrowserRouter, Route, Link } from 'react-router-dom'
import About from './about.js'
import Game from './game.js'
import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';
//import { AccessAlarm, ThreeDRotation } from 'material-ui/icons';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {isToggleOn: true};

    // This binding is necessary to make `this` work in the callback
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick() {
    this.setState(state => ({
      isToggleOn: !state.isToggleOn
    }));
  }
  closeMenu(){
    var menu = document.getElementById('menuList');
    menu.style.display="none";
  }
  render() {
    return (

      <div className="App">
        <BrowserRouter>
          <div id="wrap">
            <header id="App-header" className="App-header">
              <div className="menuBar">
                <div className="menuIcon" onClick={this.handleClick}>
              	 Menu
            		</div>

                  <div id="menuList" style={{display:this.state.isToggleOn ? "none":""}} className="menuList">
                    <a className="close btn-ghost" onClick={this.handleClick}>close</a>
                    <ul>

                      <li>
                        <Link to="/">TOP</Link>
                      </li>
                      <li>
                        <Link to="/about">ABOUT</Link>
                      </li>
                      <li>
                        <Link to="/game">GAME</Link>
                      </li>
                      <li>
                        <a href="http://blog.livedoor.jp/monsieur_rine/">BLOG</a>
                      </li>
                      <li>
                        <a href="https://twitter.com/MysterieuxB" target="_blank">TWITTER</a>
                      </li>
                    </ul>

                  </div>

              </div>
              
            </header>
            <div id="main">
              <Route exact path='/' component={Home} />
              <Route path='/about' component={About} />
              <Route path='/game' component={Game} />
            </div>
          </div>

        </BrowserRouter>
      </div>
    );
  }
}

const Home = () => (
  <div>
  <div id="top">
    <div class="inner">
        <h1>Brave Esprit</h1>
        
        <Link to="/game"><a class="explore-a"><h4>EXPLORE</h4></a></Link>
    </div>

  </div>
  <div id="top-second">

    <TwitterTimelineEmbed
      sourceType="profile"
      screenName="MysterieuxB"
      options={{height: 500}}
      theme="dark"
      transparent
    />
  </div>
  </div>
)
export default App;
