import React, { Component } from 'react';
import './assets/css/global.css';
import './assets/css/game.css';

class Game extends Component {
  render() {
    return (
    	<div>
    		<section id="game">
    			<div id="game-side">
			        <div className="bg-image"></div>

			        <div className="text-content">
			            
			        </div>
			    </div>
			    <div id="game-list-side">
			    	
			    <ul>
				    <h2>Game</h2>
						<li>
					    <h3>ならず者ロリータ</h3>
					    <a href="https://nl.braveesprit.com/" target="_blank">
					    <img src="https://nl.braveesprit.com/images/logo_full.png" alt="バナー" />
					    </a><br />
					    <p>
					    ジャンル__クライムコメディ<br />
					    要素__R18、犯罪、コメディ、ロマンス<br />
					    制作状況__10%<br />
					    公開開始日__<br />
					    価格__未定<br />
					    <br />
					    概要<br />
					    　とある犯罪組織のアンダーボス、ヤコフ。ある日彼は、新しい取引先候補と密会するため、パーティーに参加する。密会相手は、女だけで構成されているという新興の犯罪組織だ。しかし、分かっているのはロリータという取引相手の名前だけ。いったい誰なのか。<br />
					    </p>
				    </li>
				    <li>
					    <h3>アルルベルの異邦人</h3>
					    <a href="https://sia.braveesprit.com/" target="_blank">
					    <img src="https://sia.braveesprit.com/imga/ban.jpg" alt="バナー" />
					    </a><br />
					    <p>
					    ジャンル__マルチエンディング恋愛アドベンチャー<br />
					    要素__現代、洋風、ファンタジー、王宮、王子、王女<br />
					    制作状況__100%<br />
					    公開開始日__2018年<br />
					    価格__フリーウェア<br />
					    <br />
					    概要<br />
					    　父親の仕事でアルルベル王国へやって来たアビゲイルとジュリアンの兄妹。新しい環境に中々馴染めないアビゲイルと、次第に溶け込んでいくジュリアン。兄妹は王室のメンバー達と良い関係を築かなくてはいけないのだが、果たしてうまく行くのだろうか。<br />
					    </p>
				    </li>
				    <li>
					    <h3>Dying With Cry</h3>
					    <a href="https://dwc.braveesprit.com/" target="blank"><img src = "https://dwc.braveesprit.com/dwcba.JPG"/></a><br />
					    <p>
					    ジャンル__アドベンチャー　一本道<br />
					    要素__現代、死、幻想、現実、愛、兄弟<br />
					    制作状況__完成<br />
					    公開開始日__2008年4月13日<br />
					    価格__367円(税込み)<br />
					    <br />
					    概要<br />
					    　六年前の紛争で行方不明となっている兄弟を探すために、ノアは事件のあった国へ出かけた。そこで彼は死んだはずの男と出会う。男の口からある事実が語られる。そしてその後、ノアが遭遇する悲しい奇跡とは…。<br />
					    </p>
				    </li>
				    <li>
					    <h3>Feeling Sorry('Cause I Really Knew Nothing)</h3>
					    <a href="https://feelingsorry.braveesprit.com/" target="blank"><img src = "http://feelingsorry.braveesprit.com/fsban.jpg"/></a><br />
					    <p>
					    ジャンル__恋愛アドベンチャー　分岐<br />
					    要素__現代、組織、人生、危険、恋<br />
					    制作状況__完成<br />
					    PR版公開開始日__November 23,2006<br />
					    完成品公開開始日__February 25,2010<br />
					    <br />
					    概要<br />
					    　平凡な少女が、ふとしたことで郊外の謎の訓練施設に暮らす、四人の少年と知り合う。そこで彼等は、常識では考えられない生活をしていた。彼等は何者なのか。彼等の生き方を通して、何かを学ぶのだろうか。それとも、彼等と関わったことで全てを失うのだろうか。選択は、自分次第。<br />
					    </p>
				    </li>
				    <li>
					    <h3>For the Peaceful Sleep</h3>
					    <a href="https://fps.braveesprit.com/" target="blank"><img src = "https://fps.braveesprit.com/fpsb.jpg" height = "60" /></a><br />
					    <p>
					    ジャンル__アドベンチャー　少しアクション<br />
					    要素__ファンタジー、妖精、現代、紛争、組織<br />
					    制作状況__完成<br />
					    公開開始日__February 15,2006<br />
					    <br />
					    概要<br />
					    　真夜中の墓地に墓参りに来た男と、墓守のユアが出会う。共に行動していくうちに、謎の男はその墓にまつわる過去を語り出す。悲しく残酷な事実。紛争の犠牲になった少女。そしてそれは、ユア自身も知らなかった真実を暴き出すのだった。<br />
					    </p>
				    </li>
			    </ul>

			    </div>
	    		
    		</section>
    	</div>
    );
  }
}
export default Game;
