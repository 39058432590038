import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { AppBar, MenuItem, Drawer, Menu } from 'material-ui';

class NavBar extends Component {
  render() {
    return (
      <MuiThemeProvider>
        <div className="menuBar">
          <Drawer
            docked={false}
            width={100}
            open={this.props.open}
            onRequestChange={() => this.props.onToggle()}
          >

            <Menu>
            <MenuItem>React</MenuItem>
            <MenuItem>Redux</MenuItem>
            <MenuItem>React Router</MenuItem>
            <MenuItem>Material UI</MenuItem>
            <MenuItem>Electron</MenuItem>
            </Menu>
          </Drawer>
          <AppBar
            title="Brave Esprit"
            onLeftIconButtonTouchTap={ () => this.props.onToggle()}
          />
        </div>
      </MuiThemeProvider>
    );
  }
}

export default NavBar;
