import React, { Component } from 'react';
import './assets/css/global.css';
import './assets/css/about.css';

class About extends Component {
  render() {
    return (
	    <div id="about">
		    <section className="text-content about">
	      	<div className="contents-r">
	      			<h2>About</h2>
			        <h3>概要</h3>
							<p>
					　Brave Espritは、主にアドベンチャーゲームを制作するサークルです。独特な世界観を持つ作品が多いですが、多くの人に楽しんでもらうことが目標です。そのため作品には、特に年齢制限などを設けていません。男女別の制限も作りたくはないです。ストーリーは、対象を特にゲーム好きな人に絞らず、映画や小説が好きな人にも楽しめるような作りにしていきます。ゆくゆくは、シェアウェアとして販売できる水準にまでしていくことを視野に入れています。<br/>
					　設立した時期は具体的には定かではないですが、2005年頃のようです。ちまちまと作品を作っていましたが、完成には至らず習作ということで終わっていました。初めてまともに完成させたのが、For the Peaceful Sleepです。<br/>
					　意見、感想、バグ報告はTwitterがおすすめ。DMもOKです。
							</p>
					</div>
				</section>
				<section className="text-content history">
					<div className="contents-r">
						<h3>沿革</h3>
						<p>
						2021年10月22日　ならず者ロリータサイト公開<br />
						2018年10月20日　アルルベルの異邦人公開<br/>
						2010年2月25日　Feeling Sorry公開<br/>
						2008年4月13日　Dying With Cry公開<br/>
						2007年　Who Is Wrong?をゴースト・オブ・デザートに名称変更<br/>
						2006年秋頃　Dying With Cry制作開始<br/>
						2006年夏頃　Feeling Sorry制作開始<br/>
						2006年5月13日　サイト正式オープン<br/>
						2006年3月　Who Is Wrong?制作開始<br/>
						2006年2月15日　For the Peaceful Sleep公開<br/>
						2005年冬　For the Peaceful Sleep制作開始<br/>
						2005年頃　サークル立ち上げ<br/>
						</p>
					</div>
				</section>
				<section className="text-content member">
					<div className="contents-r">

						<h3>メンバー</h3>
						<h4>Myst&eacute;rieux Boy</h4>
						<p>
						Myst&eacute;rieux Boyと書いてミステリュー・ボーイと読みます。MBと略すことが多いです。<br/>
						</p>
					</div>
				</section>
				<section className="text-content notice">
					<div className="contents-r">
						<h3>著作権や転載、レビューについて</h3>
						<p>
				　このWebサイト内のデザイン、写真、素材などは、各素材の制作者に帰属します。それらを無断で使用することはできません。また、それ以外のサイト内のコンテンツは、全てBrave Espritに著作権があります。作品や文章を自分のものとして発表したり掲載することや、その他の著作権を侵害する行為を禁止します。<br/>
				　当サークルのゲームや作品などを紹介したり、レビューすることは自由です。許可を申請する必要はありません。報告はして頂けると嬉しいですが、絶対ではありません。<br/>
					
						</p>						
		    	</div>
		    </section>
		</div>

    );
  }
}
export default About;
